.App {
  font-family: sans-serif;
  text-align: center;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.roulette__history {
  width: 100%;
  height: 100%;
}

.roulette__history-container_empty-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: #fff;
  padding: 80px 40px;
  border-radius: 50%;
}

.roulette__history-container_empty {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.roulette__history-header {
  background: #e8e8e8;
  width: 100%;
  padding: 20px 0;
  border-top-left-radius: 20px;
  font-size: 17px;
}

.roulette__history-container_item {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  font-size: 15px;
  align-items: center;
  padding: 15px;
  margin-left: 50px;
}

.roulette__history-container {
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: scroll;
  overflow-x: hidden;
}

.container__roulette {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
}

.container__history {
  width: 20%;
  height: 80%;
  background: #ececec;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
}

body {
  margin: 0;
  width: 100%;
  height: 100%;
}

.n4b__scrumroulette {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}

.n4b__scrumroulette-spin {
  padding: 20px 60px;
  font-size: 30px;
  border-radius: 40px;
  transition: transform 25ms ease-out;
}

.n4b__scrumroulette-spin:active {
  transform: translateY(3px);
  transition: transform 25ms ease-out;
}

.n4b__scrumroulette-actions {
  display: flex;
  width: 50%;
  justify-content: space-around;
  align-items: center;
  gap: 20px;
}
